<template>
  <div class="wrapper">
    <el-dialog title="跟进记录" :visible.sync="dialogVisible" width="900px">
      <tp-table
        :isNeedSerialNumber="true"
        :tableData="list"
        :columns="columns"
        :totalNum="total"
        :current-page.sync="currentPage"
        :pageSize.sync="pageSize"
      />
    </el-dialog>
  </div>
</template>

<script>
import { getOrderRemarkAPI } from './api'
const columns = [
  {
    label: '时间',
    prop: 'createTime',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['createTime']}</p>
    }
  },
  {
    label: '审核人',
    prop: 'identitraceUsertyNo',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['traceUser']?.['userName']}</p>
    }
  },
  {
    label: '备注',
    prop: 'content',
    width: '200',

    customRender(h, row) {
      return <p>{row['content']}</p>
    }
  }
]
export default {
  name: 'Remark',
  props: {
    memberId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: false,
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: []
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getAfterSaleRemark(this.memberId)
    },
    pageSize() {
      this.getAfterSaleRemark(this.memberId)
    }
  },
  mounted() {
    // this.getAfterSaleRemark()
  },

  methods: {
    async getAfterSaleRemark(memberId) {
      const params = { page: this.currentPage, pageSize: this.pageSize, memberId }
      const res = await getOrderRemarkAPI(params)
      this.total = res.total || 0
      this.list = res.list || []
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .tp-table {
    .el-table__fixed-right {
      height: 100% !important;
    }
  }
  .submit {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
