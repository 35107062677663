<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="关键字" class="keywords">
          <el-input placeholder="请输入" v-model="searchData.keywordsValue" class="input-with-select" size="small">
            <el-select v-model="searchData.keywordsName" slot="prepend" placeholder="请选择" size="small">
              <el-option v-for="item in search_type_review_assign" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="下单时间">
          <el-date-picker
            style="width:305px;"
            v-model="searchData.createTimeSlot"
            size="small"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="支付时间">
          <el-date-picker
            style="width:305px;"
            v-model="searchData.payTimeSlot"
            size="small"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="领单时间">
          <el-date-picker
            style="width:305px;"
            v-model="searchData.drawTimeSlot"
            size="small"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单状态" class="item">
          <el-select v-model="searchData.orderStatusList" placeholder="请选择" size="small" multiple clearable>
            <el-option v-for="item in channelOrderStatus" :key="item.value" :label="item.name" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=" " label-width="50px">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <div class="header-btn">
      <div></div>
      <el-button
        v-checkbtn="isShowBtn(AUTH_BTN.review_assign_list_export)"
        style="margin-bottom:20px"
        type="primary"
        icon="el-icon-download"
        size="small"
        @click="downloadExcel"
        >导出</el-button
      >
    </div>

    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
    <Remark ref="remark" :memberId="memberId"></Remark>
    <div style="height:50px;"></div>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { search_type_review_assign, channelOrderStatus } from '@/enum/dict.js'
import { getListAPI, exportAPI } from './api'
import Remark from './remark.vue'
const columns = [
  {
    label: '客户名',
    prop: 'member',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['member']?.['memberName']}</p>
    }
  },
  {
    label: '手机号',
    prop: 'member',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['member']?.['mobile']}</p>
    }
  },
  {
    label: '订单号',
    prop: 'order',
    minWidth: '240',
    customRender(h, row) {
      return <p>{row['order']?.['orderNo']}</p>
    }
  },
  {
    label: '下单时间',
    prop: 'order',
    minWidth: '200',
    customRender(h, row) {
      return <p>{row['order']?.['createTime']}</p>
    }
  },
  {
    label: '商品信息',
    prop: 'goodsTitle',
    minWidth: '240',
    customRender(h, row) {
      return <p>{row['goodsTitle']}</p>
    }
  },

  {
    label: '租期',
    prop: 'order',
    minWidth: '80',
    customRender(h, row) {
      return <p>{row['order']?.['billPeriodNum']}</p>
    }
  },
  {
    label: '支付时间',
    prop: 'order',
    minWidth: '200',
    customRender(h, row) {
      return <p>{row['order']?.['payTime']}</p>
    }
  },
  {
    label: '订单状态',
    prop: 'order',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['order']?.['statusName']}</p>
    }
  },
  {
    label: '领单时间',
    prop: 'createTime',
    minWidth: '200',
    customRender(h, row) {
      return <div>{row['createTime']}</div>
    }
  },
  {
    label: '客服',
    prop: 'kfUserName',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['kfUserName']}</p>
    }
  },
  {
    label: '审核',
    prop: 'reviewUserName',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['reviewUserName']}</p>
    }
  },
  {
    label: '操作',
    minWidth: '100',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.review_assign_list_remark)}
            underline={false}
            type="primary"
            onClick={() => this.handleRemark(row)}
          >
            跟进记录
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  components: { Remark },
  data() {
    return {
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        keywordsName: '',
        keywordsValue: '',
        createTimeSlot: [],
        payTimeSlot: [],
        drawTimeSlot: [],
        orderStatusList: []
      },
      search_type_review_assign,
      channelOrderStatus,
      memberId: ''
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  mounted() {
    // this.getList()
  },
  activated() {
    this.getList()
  },
  methods: {
    async getList() {
      const {
        keywordsName,
        keywordsValue,
        createTimeSlot,
        payTimeSlot,
        drawTimeSlot,
        orderStatusList
      } = this.searchData
      let params = {
        page: this.currentPage,
        pageSize: this.pageSize,
        orderStatusList: orderStatusList.join()
      }
      if (createTimeSlot && createTimeSlot.length > 0) {
        params.leftOrderCreateDate = createTimeSlot[0]
        params.rightOrderCreateDate = createTimeSlot[1]
      }
      if (payTimeSlot && payTimeSlot.length > 0) {
        params.leftOrderPayDate = payTimeSlot[0]
        params.rightOrderPayDate = payTimeSlot[1]
      }
      if (drawTimeSlot && drawTimeSlot.length > 0) {
        params.leftCreateDate = drawTimeSlot[0]
        params.rightCreateDate = drawTimeSlot[1]
      }
      if (keywordsName) {
        params[keywordsName] = keywordsValue
      }
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },

    downloadExcel() {
      const {
        keywordsName,
        keywordsValue,
        createTimeSlot,
        payTimeSlot,
        drawTimeSlot,
        orderStatusList
      } = this.searchData
      let data = {
        orderStatusList
      }
      if (keywordsName) {
        data[keywordsName] = keywordsValue
      }
      if (createTimeSlot && createTimeSlot.length > 0) {
        data.leftOrderCreateDate = createTimeSlot[0]
        data.rightOrderCreateDate = createTimeSlot[1]
      }
      if (payTimeSlot && payTimeSlot.length > 0) {
        data.leftOrderPayDate = payTimeSlot[0]
        data.rightOrderPayDate = payTimeSlot[1]
      }
      if (drawTimeSlot && drawTimeSlot.length > 0) {
        data.leftCreateDate = drawTimeSlot[0]
        data.rightCreateDate = drawTimeSlot[1]
      }
      exportAPI(data)
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    // 跟进记录
    handleRemark({ member }) {
      this.memberId = member.id
      this.$refs.remark.dialogVisible = true
      this.$refs.remark.getAfterSaleRemark(member.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header-search {
    .el-form /deep/ {
      .keywords {
        .el-select .el-input {
          width: 100px;
        }
        .el-input-group {
          width: 320px;
        }
        .input-with-select {
          vertical-align: middle;
        }
        .input-with-select .el-input-group__prepend {
          background-color: #fff;
        }
      }
      .el-form-item {
        margin-bottom: 10px;
      }
      .el-date-editor {
        .el-range-separator {
          padding: 0;
        }
        .el-range-input {
          width: 35%;
        }
      }
      .item {
        .el-select {
          width: 305px;
        }
      }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 20px;
  }
  .header-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
